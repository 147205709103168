function newssMore() {
  const newssBtn = document.querySelector('.news__button');
  const newssItems = document.querySelectorAll('.news__item').length;
  let items;

  if (window.innerWidth < 767.98) {
    items = 2;
  } else {
    items = 3;
  }

  if (newssBtn) {
    newssBtn.addEventListener('click', () => {
      items += 1;
      const array = Array.from(document.querySelector('.news__list').children);
      const visibleItems = array.slice(0, items);

      visibleItems.forEach(el => el.classList.add('visible'));

      if (visibleItems.length === newssItems) {
        newssBtn.style.display = 'none';
      }
    });
  }
}

newssMore();

window.addEventListener('resize', () => {
  newssMore();
});
