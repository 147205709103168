import Swiper, { Navigation, Autoplay, Pagination } from "swiper";
Swiper.use([Navigation, Autoplay, Pagination]);

new Swiper(".menu", {
  slidesPerView: "auto",
  spaceBetween: 53,
  navigation: {
    nextEl: ".swiper__arrow--next",
    prevEl: ".swiper__arrow--prev",
  },
  breakpoints: {
    767.98: {
      spaceBetween: 63,
    },
    1049.98: {
      spaceBetween: 90,
    },
  }
});

new Swiper(".slider", {
  slidesPerView: "auto",
  spaceBetween: 40,
  slideToClickedSlide: true,
  centeredSlides: true,
  loop: true,
  speed: 500,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  on: {
    init() {
      this.el.addEventListener("mouseenter", () => {
        this.autoplay.stop();
      });

      this.el.addEventListener("mouseleave", () => {
        this.autoplay.start();
      });
    },
  },
});

new Swiper(".slider-2", {
  slidesPerView: "auto",
  spaceBetween: 15,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});


new Swiper(".similar-products__slider", {
  slidesPerView: "auto",
  spaceBetween: 6,
});
