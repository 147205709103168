function galleryMore() {
  const galleryBtn = document.querySelector('.gallery__btn');
  const galleryItems = document.querySelectorAll('.gallery__item').length;
  let items = 4;

  if (galleryBtn) {
    galleryBtn.addEventListener('click', () => {
      items += 2;
      const array = Array.from(document.querySelector('.gallery__list').children);
      const visibleItems = array.slice(0, items);

      visibleItems.forEach(el => el.classList.add('visible'));

      if (visibleItems.length === galleryItems) {
        galleryBtn.style.display = 'none';
      }
    });
  }
}

galleryMore();

window.addEventListener('resize', () => {
  galleryMore();
});
