import noUiSlider from "nouislider";

document.querySelectorAll(".range__slider").forEach((el) => {
  const filterPriceMin = el.closest(".range").querySelector(".min");
  const filterPriceMax = el.closest(".range").querySelector(".max");
  const inputs = [filterPriceMin, filterPriceMax];

  const minPrice = +filterPriceMin.getAttribute("data-min");
  filterPriceMin.value = minPrice.toLocaleString() + " ₽";

  const maxPrice = +filterPriceMax.getAttribute("data-max");
  filterPriceMax.value = maxPrice.toLocaleString() + " ₽";

  noUiSlider.create(el, {
    range: {
      min: minPrice,
      max: maxPrice,
    },
    behaviour: "drag",
    connect: true,
    start: [minPrice, maxPrice],
    step: 1,
  });

  el.noUiSlider.on("update", (values) => {
    let [downPrice, upPrice] = values;

    downPrice = Number(downPrice).toLocaleString() + " ₽";
    upPrice = Number(upPrice).toLocaleString() + " ₽";

    filterPriceMin.value = downPrice;
    filterPriceMax.value = upPrice;
  });

  inputs.forEach(function (input, handle) {
    input.addEventListener("change", function () {
      let value = this.value;
      value = value.replace(/\s+/g, "");
      value = parseInt(value);

      el.noUiSlider.setHandle(handle, value);
    });
  });
})
