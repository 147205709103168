import JustValidate from "just-validate";
import Inputmask from "inputmask";

document.querySelectorAll(".form").forEach((form) => {
  const selector = form.querySelector("input[type='tel']");
  const im = new Inputmask("+7 (999)-999-99-99");
  im.mask(selector);

  const validation = new JustValidate(form, {
    errorLabelStyle: {
      color: "red",
    },
  });

  validation
    .addField(".input-name", [
      {
        rule: "required",
        value: true,
        errorMessage: "Заполните имя!",
      },
      {
        rule: "minLength",
        value: 3,
        errorMessage: "Поле должно содержать минимум 3 символа!",
      },
      {
        rule: "maxLength",
        value: 15,
        errorMessage: "Поле должно содержать не более 15 символов!",
      },
    ])
    .addField(".input-tel", [
      {
        rule: "required",
        value: true,
        errorMessage: "Заполните телефон!",
      },
      {
        rule: "function",
        validator: () => {
          const phone = selector.inputmask.unmaskedvalue();
          return phone.length === 10;
        },
        errorMessage: "Введите корректный телефон!",
      },
    ])

  validation.onSuccess((e) => {
    let formData = new FormData(e.target);

    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          xhr.onload = () => {
            const popupActive = document.querySelector(".popup.open");

            if (popupActive) {
              popupActive.classList.remove("open");
            }

            if (xhr.response === "success") {
              document.querySelector('#popup-thanks').classList.add("open");
              let paddingOffset = window.innerWidth - document.body.offsetWidth + "px";
              document.body.style.paddingRight = paddingOffset;
              document.body.classList.add("lock");

              setTimeout(() => {
                document.querySelector('#popup-thanks').classList.remove("open");
                document.body.style.paddingRight = "0px";
                document.body.classList.remove("lock");
              }, 3000);
            }
          };
        }
      }
    };

    xhr.open("POST", "mail.php", true);
    xhr.send(formData);

    e.target.reset();
  });
});
