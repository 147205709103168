function certificatesMore() {
  const certificatesBtn = document.querySelector('.certificates__btn');
  const certificatesItems = document.querySelectorAll('.certificates__item').length;
  let items;

  if (window.innerWidth < 767.98) {
    items = 3;
  } else {
    items = 9;
  }

  if (certificatesBtn) {
    certificatesBtn.addEventListener('click', () => {
      items += 3;
      const array = Array.from(document.querySelector('.certificates__list').children);
      const visibleItems = array.slice(0, items);

      visibleItems.forEach(el => el.classList.add('visible'));

      if (visibleItems.length === certificatesItems) {
        certificatesBtn.style.display = 'none';
      }
    });
  }
}

certificatesMore();

window.addEventListener('resize', () => {
  certificatesMore();
});
