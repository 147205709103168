; (function () {
  const map = document.querySelector('.map');
  if (!map) return;

  let ymapInit = function () {
    if (typeof ymaps === 'undefined') {
      return;
    }

    ymaps.ready(function () {
      let myMap = new ymaps.Map('shops-ymap', {
        center: [58.633087, 49.629521],
        zoom: 10,
      }, {
        searchControlProvider: 'yandex#search'
      }),

        myPlacemark1 = new ymaps.Placemark([58.633087, 49.629521], {
          balloonContent: `
            <div class="balloon">
              <div class="balloon__address">г. Киров, ул. Металлургов, 8</div>
              <ul class="balloon__contacts">
                <li>
                  <img src="/img/svg/call_2.svg" width="14" height="16" alt="Call">
                  <a href="tel:+78332211777">+7 (8332) 211-777</a>
                </li>
                <li>
                  <img src="/img/svg/mail_2.svg" width="16" height="16" alt="Call">
                  <a href="mailto:Santehmarket43@yandex.ru">Email: Santehmarket43@yandex.ru</a>
                </li>
                <li>
                  <img src="/img/svg/time.svg" width="16" height="16" alt="Call">
                  <span>Пн-Пт 9-18, Сб-Вс Выходной</span>
                </li>
              </ul>
            </div>
          `,
        }, {
          iconLayout: 'default#image',
          iconImageHref: '/img/svg/marker-red.svg',
          iconImageSize: [56, 56],
          iconImageOffset: [-30, -40]
        });

        myPlacemark2 = new ymaps.Placemark([58.495017, 49.720960], {
          balloonContent: `
            <div class="balloon">
              <div class="balloon__address">г. Киров ул. Воровского 35, офис 506</div>
              <ul class="balloon__contacts">
                <li>
                  <img src="/img/svg/call_2.svg" width="14" height="16" alt="Call">
                  <a href="tel:+78332211777">+7 (8332) 211-777</a>
                </li>
                <li>
                  <img src="/img/svg/mail_2.svg" width="16" height="16" alt="Call">
                  <a href="mailto:Santehmarket43@yandex.ru">Email: Santehmarket43@yandex.ru</a>
                </li>
                <li>
                  <img src="/img/svg/time.svg" width="16" height="16" alt="Call">
                  <span>Пн-Пт 9-18, Сб-Вс Выходной</span>
                </li>
              </ul>
            </div>
          `,
        }, {
          iconLayout: 'default#image',
          iconImageHref: '/img/svg/marker-blue.svg',
          iconImageSize: [56, 56],
          iconImageOffset: [-35, -90]
        });

        myPlacemark3 = new ymaps.Placemark([58.612966, 49.679054], {
          balloonContent: `
            <div class="balloon">
              <div class="balloon__address">г. Киров ул. Ленина 19, офис 3</div>
              <ul class="balloon__contacts">
                <li>
                  <img src="/img/svg/call_2.svg" width="14" height="16" alt="Call">
                  <a href="tel:+78332211777">+7 (8332) 211-777</a>
                </li>
                <li>
                  <img src="/img/svg/mail_2.svg" width="16" height="16" alt="Call">
                  <a href="mailto:Santehmarket43@yandex.ru">Email: Santehmarket43@yandex.ru</a>
                </li>
                <li>
                  <img src="/img/svg/time.svg" width="16" height="16" alt="Call">
                  <span>Пн-Пт 9-18, Сб-Вс Выходной</span>
                </li>
              </ul>
            </div>
          `,
        }, {
          iconLayout: 'default#image',
          iconImageHref: '/img/svg/marker-blue.svg',
          iconImageSize: [56, 56],
          iconImageOffset: [-35, -90]
        });

      myMap.controls.remove("geolocationControl"); // удаляем геолокацию
      myMap.controls.remove("searchControl"); // удаляем поиск
      myMap.controls.remove("trafficControl"); // удаляем контроль трафика
      myMap.controls.remove("typeSelector"); // удаляем тип
      // map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
      // map.controls.remove('zoomControl'); // удаляем контрол зуммирования
      myMap.controls.remove("rulerControl"); // удаляем контрол правил
      myMap.behaviors.disable(["scrollZoom"]); // отключаем скролл карты (опционально)
      myMap.geoObjects.add(myPlacemark1).add(myPlacemark2).add(myPlacemark3);
    });
  };

  let ymapLoad = function () {
    let script = document.createElement('script');
    script.src = 'https://api-maps.yandex.ru/2.1/?apikey=15a9556f-97ac-4d81-a7b9-044c54e1555f&lang=ru_RU';
    document.body.appendChild(script);
    script.addEventListener('load', ymapInit);
  }

  let checkYmapInit = function () {
    let mapTop = map.getBoundingClientRect().top;
    let scrollTop = window.scrollY;
    let mapOffsetTop = scrollTop + mapTop;

    if (scrollTop + window.innerHeight > mapOffsetTop) {
      ymapLoad();
      window.removeEventListener('scroll', checkYmapInit);
    }
  };

  window.addEventListener('scroll', checkYmapInit);
  checkYmapInit();
})();
