const popupLink = document.querySelectorAll('.popup-link');
const popupCloseIcon = document.querySelectorAll('.close-popup');
const lockPadding = document.querySelectorAll('.lock-padding');
const body = document.querySelector('body');
const timeout = 800;
let unlock = true;

popupLink.forEach(el => {
	el.addEventListener('click', (e) => {
		const popupName = el.getAttribute('href').replace('#', '');
		const curentPopup = document.getElementById(popupName);
		popupOpen(curentPopup);
		e.preventDefault();
	});
});

popupCloseIcon.forEach(el => {
	el.addEventListener('click', (e) => {
		popupClose(el.closest('.popup'));
		e.preventDefault();
	});
});

function popupOpen(curentPopup) {
	if (curentPopup && unlock) {
		const popupActive = document.querySelector('.popup.open');
		popupActive ? popupClose(popupActive, false) : bodyLock();

		curentPopup.classList.add('open');
		curentPopup.addEventListener('click', function (e) {
			if (!e.target.closest('.popup__content')) {
				popupClose(e.target.closest('.popup'));
			}
		});
	}
}

function popupClose(popupActive, doUnLock = true) {
	if (unlock) {
		popupActive.classList.remove('open');
		if (doUnLock) {
			bodyUnLock();
		}
	}
}

function bodyLock() {
	const lockPaddingValue = window.innerWidth - body.offsetWidth + 'px';
	lockPadding.forEach(el => {
		el.style.paddingRight = lockPaddingValue;
	});
	body.style.paddingRight = lockPaddingValue;
	body.classList.add('lock');

	unlock = false;
	setTimeout(() => {
		unlock = true;
	}, timeout);
}

function bodyUnLock() {
	setTimeout(() => {
		lockPadding.forEach(el => {
			el.style.paddingRight = '0px';
		});
		body.style.paddingRight = '0px';
		body.classList.remove('lock');
	}, timeout);

	unlock = false;
	setTimeout(() => {
		unlock = true;
	}, timeout);
}

document.addEventListener('keydown', function (e) {
	if (e.key === 'Escape') {
		const popupActive = document.querySelector('.popup.open');
		popupClose(popupActive);
	}
});
