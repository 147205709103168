const btns = document.querySelectorAll(".tabs__btn");
const tabs = document.querySelectorAll(".tabs__content");

btns.forEach((btn, index) => {
  btns[0].click();
  btn.addEventListener("click", () => {
    btns.forEach((btn) => btn.classList.remove("active"));
    tabs.forEach((tab) => tab.classList.remove("active"));

    btn.classList.add("active");
    tabs[index].classList.add("active");
  });
});
