const catalogOpacity = document.querySelector(".catalog__inner");
const btnFilter = document.querySelector(".catalog__filter");
const btnClose = document.querySelector(".catalog__close");
const filter = document.querySelector(".catalog__aside");

if (btnFilter) {
  btnFilter.addEventListener("click", () => {
    catalogOpacity.classList.toggle("active");
    btnFilter.classList.toggle("active");
    filter.classList.toggle("active");
  })
}

if (btnClose) {
  btnClose.addEventListener("click", () => {
    catalogOpacity.classList.remove("active");
    btnFilter.classList.remove("active");
    filter.classList.remove("active");
  })
}
