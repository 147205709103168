// подключения компонентов
import './components/fancybox';
import './components/form-validate';
import './components/textarea';
import './components/form';
import './components/popup';
import './components/burger';
import './components/clipping';
import './components/certificates';
import './components/news';
import './components/gallery';
import './components/video-gallery';
import './components/spoller';
import './components/show-more';
import './components/nouislider';
import './components/filter';
import './components/tabs';
import './components/dimamic-adapt';
import './components/swiper';
import './components/map';
