function clipping() {
  const clippingText = document.querySelectorAll('.news__text');
  clippingText.forEach(el => {
    if (!el.dataset.originalText) {
      el.dataset.originalText = el.innerText; // Сохранение оригинального текста
    }

    if (window.innerWidth < 1049.98 && el.innerText.length > 448) {
      el.innerText = el.innerText.slice(0, 448) + '...';
    }

    if (window.innerWidth > 1049.98 && el.innerText.length > 448) {
      el.innerText = el.dataset.originalText; // Восстановление оригинального текста
    }
  });
}

clipping();

window.addEventListener('resize', () => {
  clipping();
});
