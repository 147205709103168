const burger = document.querySelector(".burger");
const close = document.querySelector(".nav__close");
const content = document.querySelector(".header__nav");
const opacity = document.querySelector('.header__opacity');


if (burger) {
  burger.addEventListener("click", () => {
    document.body.classList.add("lock");
    close.classList.add("active");
    content.classList.add("active");
    opacity.classList.add("active");
  });
}

if (close) {
  close.addEventListener("click", () => {
    document.body.classList.remove("lock");
    close.classList.remove("active");
    content.classList.remove("active");
    opacity.classList.remove("active");
  });
}

if (opacity) {
  opacity.addEventListener("click", () => {
    document.body.classList.remove("lock");
    close.classList.remove("active");
    content.classList.remove("active");
    opacity.classList.remove("active");
  });
}
