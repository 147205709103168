const spollerBtn = document.querySelector(".spoller__btn");
const spollerWrap = document.querySelector(".spoller__wrap");

if (spollerBtn) {
  spollerBtn.addEventListener("click", () => {
    spollerWrap.classList.toggle('open');

    spollerBtn.classList.toggle("open")
      ? (spollerBtn.textContent = "Скрыть все")
      : (spollerBtn.textContent = "Показать все");
    spollerWrap.style.maxHeight
      ? (spollerWrap.style.maxHeight = null)
      : (spollerWrap.style.maxHeight = spollerWrap.scrollHeight + "px");
  })
}
