function videoGalleryMore() {
  const videoGalleryBtn = document.querySelector('.video-gallery__btn');
  const videoGalleryItems = document.querySelectorAll('.video-gallery__item').length;
  let items;

  if (window.innerWidth < 767.98) {
    items = 3;
  } else {
    items = 6;
  }

  if (videoGalleryBtn) {
    videoGalleryBtn.addEventListener('click', () => {
      items += 2;
      const array = Array.from(document.querySelector('.video-gallery__list').children);
      const visibleItems = array.slice(0, items);

      visibleItems.forEach(el => el.classList.add('visible'));

      if (visibleItems.length === videoGalleryItems) {
        videoGalleryBtn.style.display = 'none';
      }
    });
  }
}

videoGalleryMore();

window.addEventListener('resize', () => {
  videoGalleryMore();
});
