import { Fancybox } from "@fancyapps/ui";

let galerias = document.querySelectorAll('.fancybox');
for (let i = 0; i < galerias.length; i++) {
  galerias[i].id = 'gal' + i;
  Fancybox.bind("#gal" + i + ' a', {
    groupAll: true,
    Thumbs: {
      type: "classic",
    },
  });
}
