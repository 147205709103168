const init = () => {
  const forms = document.querySelectorAll(".form");

  for (const form of forms) {
    const groups = form.querySelectorAll(".form__group");

    for (const group of groups) {
      const input = group.querySelector(".form__input");

      if (input.value.length > 0) {
        group.classList.add("active");
      }

      input.addEventListener("input", () => {
        if (input.value.length > 0) {
          group.classList.add("active");
        } else {
          group.classList.remove("active");
        }
      });
    }
  }
};

window.addEventListener("DOMContentLoaded", init);
